import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { IoIosArrowRoundForward } from "react-icons/io"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Blog from "../components/Blog"
import InnerHero from "../components/InnerHero"

export const query = graphql`
  {
    allPrismicPost(sort: { order: DESC, fields: data___post_date }) {
      nodes {
        data {
          post_author {
            text
          }
          post_author_image {
            alt
            dimensions {
              height
              width
            }
            fixed {
              ...GatsbyPrismicImageFixed_withWebp
            }
          }
          post_author_info {
            text
          }
          post_author_link {
            url
          }
          post_body {
            text
          }
          post_category
          post_date(formatString: "MMMM Do, YYYY")
          post_preview_description {
            text
          }
          post_title {
            text
          }
          slug {
            text
          }
          post_image_preview {
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
            alt
          }
        }
        id
        uid
      }
    }
  }
`

const BlogPage = ({ page, data }) => {
  const blogs = data.allPrismicPost.nodes

  return (
    <Layout>
      <Seo
        title="Web design & web development blog - Vermont Web Development"
        description="The blog post about web design, web development, and Jamstack technology. Top Vermont web design & development services. Vermont web development services build high-performing, safe, SEO friendly, and easy to add or edit your content with Content Management Systems (CMS)."
      />
      <main>
        <section id="blog">
          <div className="section u-padding-bottom">
            {!page && (
              <>
                <InnerHero>
                  <h1 className="heading__main-title heading__main-title--black">
                    Blog
                  </h1>
                  <h2 className="heading__main-title--sub u-no-effect">
                    The insights on topics that matter.
                  </h2>
                </InnerHero>
              </>
            )}
          </div>
          <div className="blog row u-margin-top u-margin-bottom-medium">
            <div className="col-3-of-3">
              {blogs &&
                blogs.map(item => {
                  const { id } = item
                  const {
                    post_title,
                    post_image_preview,
                    post_date,
                    post_author,
                    post_preview_description,
                    slug,
                  } = item.data
                  const fluid = post_image_preview.fluid

                  const truncate =
                    post_preview_description.text.length > 160
                      ? `${post_preview_description.text.substring(0, 90)}...`
                      : post_preview_description.text

                  return (
                    <BlogWrapper>
                      <article key={id}>
                        <Link to={`/blog/${slug.text}`}>
                          <figure>
                            <Image fluid={fluid} alt={post_image_preview.alt} />
                          </figure>
                        </Link>

                        <div className="blog__author">
                          <p className="paragraph">
                            {post_author.text} / <time>{post_date}</time>
                          </p>
                        </div>

                        <Link to={`/blog/${slug.text}`}>
                          <h3 className="heading-secondary--title u-margin-top-small u-margin-bottom-medium">
                            {post_title.text}
                          </h3>
                        </Link>

                        <p className="paragraph u-margin-bottom-medium">
                          {truncate}
                        </p>

                        <Link
                          to={`/blog/${slug.text}`}
                          className="paragraph__link"
                        >
                          Read More <IoIosArrowRoundForward />
                        </Link>
                      </article>
                    </BlogWrapper>
                  )
                })}
            </div>
          </div>
          {/* <Blog blogs={blogs} /> */}
        </section>
      </main>
    </Layout>
  )
}

const BlogWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  margin-bottom: 2rem;

  @media screen and (max-width: 990px) {
    height: 100%;
  }

  .gatsby-image-wrapper {
    background-size: cover;
    height: 23rem;
  }

  .heading-secondary--title {
    color: #000;
    font-size: 2.5rem;

    &:hover {
      color: #52b788;
      transition: all 0.8s;
    }
  }

  .paragraph__link {
    color: #000;
    font-size: 1.6rem;

    svg {
      vertical-align: middle;
    }

    &:hover {
      color: #52b788;
      transition: all 0.8s;
    }
  }

  .paragraph {
    color: #777;
    margin-top: 5rem;
  }

  .blog__author {
    .paragraph,
    time {
      color: #777;
      font-size: 1.25rem;
    }
  }
`

export default BlogPage
